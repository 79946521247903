import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import ProjectFour from './ProjectFour';
import ProjectOne from './ProjectOne';
import ProjectTwo from './ProjectTwo';
import ProjectThree from './ProjectThree';

class Works extends Component {
    constructor() {
        super();
        this.state = {
            project1: true,
            project2: false,
            project3: false,
            project4: false,
        };
        this.hideComponent = this.hideComponent.bind(this);
    }

    hideComponent(name) {
        // console.log(name);
        switch (name) {
          case "project1":
            this.setState({ 
                project1: true,
                project2: false,
                project3: false,
                project4: false,
            });
            break;
          case "project2":
            this.setState({ 
                project1: false,
                project2: true,
                project3: false,
                project4: false,
            });
            break;
          case "project3":
            this.setState({ 
                project1: false,
                project2: false,
                project3: true,
                project4: false, 
            });
            break;
          case "project4":
            this.setState({ 
                project1: false,
                project2: false,
                project3: false,
                project4: true, 
            });
            break;
          default:
            return null;
        }
    }

    render() {
        const { project1, project2, project3, project4 } = this.state;
        return (
            <Container fluid className="text-center mx-auto">
                <h3 className="projects">Projects</h3>
                <div>
                    <div className={`project-number ${project1 ? 'project-number-active' : ''}`} onClick={() => this.hideComponent("project1")}>01</div>
                    <div className={`project-number ${project2 ? 'project-number-active' : ''}`} onClick={() => this.hideComponent("project2")}>02</div>
                    <div className={`project-number ${project3 ? 'project-number-active' : ''}`} onClick={() => this.hideComponent("project3")}>03</div>
                    <div className={`project-number ${project4 ? 'project-number-active' : ''}`} onClick={() => this.hideComponent("project4")}>04</div>
                </div>
                <div className="box">
                {project1 && <ProjectOne />}
                {project2 && <ProjectTwo />}
                {project3 && <ProjectThree />}
                {project4 && <ProjectFour />}
                </div>
            </Container>
        )
    }
}

export default Works;
