import React, { Component } from 'react';

class ProjectOne extends Component {
    render() {
        return (
            <div className="project">
                <h2 className="project-header">Trash talks</h2>
                <img src="proj3-asset1.png" alt="project-demo"/>
                <img src="proj3-asset3.png" alt="project-demo"/>
                <img src="proj3-asset2.png" alt="project-demo"/>
                <div className="project-text mt-3">
                    <h2 className="project-title">Overview</h2>
                    <p>A team effort with three other developers, we built an app that connects recycling agents to residents in an effort to cultivate recycling habits and bring ease and convenience to disposing of unused items at home.</p>
                    <h2 className="project-title">My Role</h2>
                    <p>Front-end Development</p>
                    <h2 className="project-title">Languages</h2>
                    <p>Ruby on Rails, PostgreSQL, Devise, Javascript, SCSS, Bootstrap</p>
                    <h2 className="project-title">Project</h2>
                    <p>The project was birthed from an idea to ease the load of 'rag 'n' bone' collectors by bringing them to the residents directly through the app - providing doorstep collection and easy cash for trash. Both recyclers and collectors are able to view or access recycling lists in their vicinity with the help of Google Maps API.<br/> I was overall responsible for the front-end development of the site. Coded with SCSS and utilized Bootstrap grid system to align layouts of our pages.</p>
                    <a href="https://github.com/amirahsham01/trashtalks" target="_blank" rel="noopener noreferrer" className="project-link">View Code</a>
                    <a href="https://trash-talks.herokuapp.com/" target="_blank" rel="noopener noreferrer" className="project-link">Check out the site</a>
                </div>
            </div>
        )
    }
}

export default ProjectOne;
