import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class NavContainer extends Component {

    state = {
        responsive: false
    }

    showNav() {}

    render() {
        return (
            <div className="nav sticky-top">
                <NavLink exact to="/" activeClassName="nav-text-active" className="about">About</NavLink>
                
                <NavLink exact to="/works" activeClassName="nav-text-active" className="works">Works</NavLink>

                <NavLink exact to="/contact" activeClassName="nav-text-active" className="contact">Contact</NavLink>
            </div>
        )
    }
}

