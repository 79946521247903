import React, { Component } from 'react';

class ProjectTwo extends Component {
    render() {
        return (
            <div className="project">
                <h2 className="project-header">Rhyme or Reason</h2>
                <img src="proj2-asset1.png" alt="project-demo"/>
                <div className="project-text mt-3">
                    <h2 className="project-title">Overview</h2>
                    <p>A poetry database, and a full-stack application using Node.js, Express and MongoDB. The concept was to build an online platform where users can share poetry, gather literary ideas and inspiration. Users can also view the existing archive of poetry that is made public. Incorporated sign up and log in functionality with an authorization flow.</p>
                    <h2 className="project-title">Languages</h2>
                    <p>Back-end: MongoDB, Express, Node.js<br/> Front-end: Bootstrap, MDBootstrap, jQuery</p>
                    <a href="https://github.com/amirahsham01/rhymeorreason" target="_blank" rel="noopener noreferrer" className="project-link">View Code</a>
                    <a href="https://rhymeorreason.herokuapp.com/" target="_blank" rel="noopener noreferrer" className="project-link">Check out the site</a>
                </div>
            </div>
        )
    }
}

export default ProjectTwo;