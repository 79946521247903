import React, { Component } from 'react';
import FooterTicker from './FooterTicker';
import TickerTwo from './TickerTwo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Footer extends Component {
    render() {
        return (
            <div className="footer fixed-bottom">
                <div className="connect-two">
                    <TickerTwo/>
                </div>
                <div className="connect">
                    <FooterTicker/>
                </div>
                <div className="social-media d-flex justify-content-center">
                    <a href="https://linkedin.com/in/amirah-sham/" target="_blank" rel="noopener noreferrer" className="social-links"><FontAwesomeIcon icon={['fab', 'linkedin-in']}/></a>
                    <a href="https://github.com/amirahsham01/" target="_blank" rel="noopener noreferrer" className="social-links"><FontAwesomeIcon icon={['fab', 'github']} /></a>
                    <a href="https://www.behance.net/amirah-sham" target="_blank" rel="noopener noreferrer" className="social-links"><FontAwesomeIcon icon={['fab', 'behance']} /></a>
                    <a href="https://www.instagram.com/amirahsham/" target="_blank" rel="noopener noreferrer" className="social-links"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                </div>
            </div>
        )
    }
}

export default Footer;
