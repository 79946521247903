import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Contact extends Component {
    constructor() {
        super();
        this.submitForm = this.submitForm.bind(this);
        this.state = {
          status: ""
        };
    }

    submitForm(e) {
        e.preventDefault();
        const form = e.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
          if (xhr.readyState !== XMLHttpRequest.DONE) return;
          if (xhr.status === 200) {
            form.reset();
            this.setState({ status: "SUCCESS" });
          } else {
            this.setState({ status: "ERROR" });
          }
        };
        xhr.send(data);
    }

    render() {
        const { status } = this.state;
        return (
            <Container fluid>
                <div className="contact-box">
                    <Row className="h-100">
                        <Col className="align-self-center">
                            <div className="ml-2">
                                <h3 className="contact-header">Just say hi?</h3>
                                <p>I'm always open to discussions.<br/>Mail me at <a className="email" href="mailto:nuramirahsham@gmail.com">nuramirahsham@gmail.com</a></p>
                            </div>
                            <div className="contact-socmed ml-2">
                                <p className="mt-5">Follow me on</p>
                                <a href="https://linkedin.com/in/amirah-sham/" target="_blank" rel="noopener noreferrer" className="socmed-links"><FontAwesomeIcon icon={['fab', 'linkedin-in']}/></a>
                                <a href="https://github.com/amirahsham01/" target="_blank" rel="noopener noreferrer" className="socmed-links"><FontAwesomeIcon icon={['fab', 'github']} /></a>
                                <a href="https://www.behance.net/amirah-sham" target="_blank" rel="noopener noreferrer" className="socmed-links"><FontAwesomeIcon icon={['fab', 'behance']} /></a>
                                <a href="https://www.instagram.com/amirahsham/" target="_blank" rel="noopener noreferrer" className="socmed-links"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                            </div>
                        </Col>
                        <Col className="align-self-center text-center form">
                            <form className="p-2" onSubmit={this.submitForm} action="https://formspree.io/xjvpbllb" method="POST">
                                <input className="form-input" required={true} placeholder="Name" type="text" name="name" />
                                <input className="form-input" required={true} placeholder="Email" type="email" name="_replyto" />
                                <textarea className="form-input" required={true} rows="5" placeholder="Your Message" name="message" />
                                {status === "SUCCESS" ? <p>Thanks for the message!</p> : <button className="btn-form">Submit</button>}
                                {status === "ERROR" && <p>Ooops! There was an error.</p>}
                            </form>
                        </Col>
                    </Row>
                </div>
            </Container>
        )
    }
}

export default Contact;
