import React, { Component } from 'react';

class ProjectFour extends Component {
    render() {
        return (
            <div className="project">
                <h2 className="project-header">Space Invaders - A Star Wars Adaptation</h2>
                <img src="proj1-asset1.png" alt="project-demo"/>
                <img src="proj1-asset2.png" alt="project-demo"/>
                <div className="project-text mt-3">
                    <h2 className="project-title">Overview</h2>
                    <p>A course project, I built a fixed shooter game using vanilla Javascript, HTML and CSS. It is a fun adaptation of Space Invaders, themed after Star Wars. The objective of the game is to defeat the Imperials by firing lasers, survive by tactfully avoiding theirs, and prevent them from reaching the bottom of the screen.</p>
                    <h2 className="project-title">Languages</h2>
                    <p>Javascript, HTML and CSS</p>
                    <a href="https://github.com/amirahsham01/imperial-invaders" target="_blank" rel="noopener noreferrer" className="project-link">View Code</a>
                    <a href="https://amirahsham01.github.io/imperial-invaders/" target="_blank" rel="noopener noreferrer" className="project-link">Check out the site </a>
                </div>
            </div>
        )
    }
}

export default ProjectFour;
