import React from 'react';
import './index.css';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import Home from './component/Home';
import Works from './component/Works';
import Contact from './component/Contact';
import NavContainer from './component/Nav-Container';
import Footer from './component/Footer';
import initFontAwesome from "./initFontAwesome";

initFontAwesome();

function App() {
  return (
    <Router>
      <NavContainer/>
      <Switch>
        <Route path="/" exact render={() => <Home/>} />
        <Route path="/works" exact render={() => <Works/>} />
        <Route path="/contact" exact render={() => <Contact/>} />
      </Switch>
      <Footer/>
    </Router>
  );
}

export default App;
