import React, { Component } from 'react';
import Ticker from 'react-ticker';

class FooterTicker extends Component {
    render() {
        return (
            <div>
                <Ticker direction="toRight" offset="100%" speed={10}>
                    {(index) => (
                        <h3 className="mx-3" style={{fontSize:21+ 'px'}}>Let's connect</h3>
                    )}
                </Ticker>
            </div>
        )
    }
}

export default FooterTicker;