import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Intro extends Component {
    render() {
        return (
            <Container fluid>
                <div className="intro">
                    <p className="intro-header">Hey there,<br/> I'm <span>Amirah Sham</span>.</p>
                    <p className="introduction">I am a front-end developer with experience in Visual Design & Communication. I love shaping ideas and translating them into visual and interactive experiences.</p>
                    <p className="introduction">In my non-code time, I watch anime, drink too much bubble tea and ride bicycles. I'm currently seeking new opportunities, either freelance or <s>full-time</s>. Get in touch and say hello. <FontAwesomeIcon icon={['fas', 'smile-wink']} /></p>
                </div>
                <div className="intro-two">
                    <h4 className="skills-title">What I specialise in,</h4>
                    <Row>
                        <Col xs={6} md={4}>
                            <div className="square">
                                <div className="square-content">
                                    <FontAwesomeIcon icon={['fas', 'code']} className="skill-icon" />
                                    <p>Web Development</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={6} md={4}>
                            <div className="square">
                                <div className="square-content">
                                    <FontAwesomeIcon icon={['fas', 'laptop-code']} className="skill-icon" />
                                    <p>Front-end Development</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={4}>
                            <div className="square">
                                <div className="square-content">
                                    <FontAwesomeIcon icon={['far', 'object-group']} className="skill-icon" />
                                    <p>UI/UX Design</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <h4 className="skills-title mt-5">My skills</h4>
                    <Row>
                        <Col xs={6} md={3} className="skill-set">
                            <ul>
                                <p><u>Languages</u></p>
                                <li>HTML5</li>
                                <li>CSS3 & Sass</li>
                                <li>Javascript</li>
                                <li>Ruby</li>
                                <li>PostgreSQL</li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className="skill-set">
                            <ul>
                                <p><u>Frameworks</u></p>
                                <li>React.js</li>
                                <li>Node.js</li>
                                <li>Express.js</li>
                                <li>Ruby on Rails</li>
                                <li>MongoDB</li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className="skill-set">
                            <ul>
                                <p><u>Tools</u></p>
                                <li>Git & Github</li>
                                <li>Postman</li>
                                <li>CLI</li>
                                <li>Bootstrap</li>
                                <li>jQuery</li>
                            </ul>
                        </Col>
                        <Col xs={6} md={3} className="skill-set">
                            <ul>
                                <p><u>Design</u></p>
                                <li>Figma</li>
                                <li>Adobe XD</li>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                                <li>After Effects</li>
                            </ul>
                        </Col>
                    </Row>
                    <hr/>
                </div>
                <div className="copyright">
                    Copyright <FontAwesomeIcon icon={['far', 'copyright']} /> 2020. All rights reserved.
                </div>
            </Container>
        )
    }
}

export default Intro;
