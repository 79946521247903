import React, { Component } from 'react';

class ProjectThree extends Component {
    render() {
        return (
            <div className="project">
                <h2 className="project-header">Todo.it</h2>
                <img src="proj4-asset1.png" alt="project-demo"/>
                <div className="project-text mt-3">
                    <h2 className="project-title">Overview</h2>
                    <p>A task management app that helps manage and organize your day-to-day tasks. Do your to-dos with Todo.it!</p>
                    <h2 className="project-title">Languages</h2>
                    <p>Back-end: MongoDB, Express, Node.js<br/> Front-end: React.js, Bootstrap, jQuery</p>
                    <a href="https://github.com/amirahsham01/todo-it" target="_blank" rel="noopener noreferrer" className="project-link">View Code</a>
                    <a href="https://todo-itapp.herokuapp.com/" target="_blank" rel="noopener noreferrer" className="project-link">Check out the site</a>
                </div>
            </div>
        )
    }
}

export default ProjectThree;